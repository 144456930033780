<template>
  <el-card class="box-card">
    <!-- <div slot="header" class="clearfix">
      <h3 class="inBlock">门店邀约排行</h3>
    </div> -->
     <div slot="header" class="clearfix">
			<h3>
				<el-tooltip class="item" effect="dark" content="返回上一页" placement="bottom">
				    <i @click="goBack" style="margin-right: 40px; cursor: pointer;" class="el-icon-arrow-left"></i>
				</el-tooltip>
				门店邀约排行
			</h3>
		</div>
        <!-- 搜索条件 -->
        <!-- <el-form ref="form" :model="sizeForm" label-width="100px" size="mini">
            <el-row>
                <el-col :span="4">
                    <el-form-item label="奖项id：">
                        <el-input v-model="sizeForm.luckdraws_id" placeholder="请输入对应奖项id"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="姓名：">
                        <el-input v-model="sizeForm.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="手机号：">
                        <el-input v-model="sizeForm.binding" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                </el-col> -->
                <!-- <el-col :span="4">
                    <el-form-item label="用户身份：">
                        <el-select placeholder="请选择用户身份" clearable v-model="sizeForm.is_nine_vip">
                            <el-option
                                v-for="item in statusList"
                                :key="item.key"
                                :label="item.name"
                                :value="item.key"/>
                        </el-select>
                    </el-form-item>
                </el-col> -->
                <!-- <el-col :span="7">
                    <el-form-item label="到期时间：">
                        <el-date-picker
                            v-model="dateTime"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
                        </el-date-picker>
                    </el-form-item>
                </el-col> -->
                <!-- <el-col :span="6">
                    <el-form-item >
                        <el-button size="small" type="primary" @click="onSearch">搜索</el-button>
                        <el-button size="small" type="primary" @click="exportShop">导出</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form> -->
        <!-- 表格内容 -->
         <el-table
            ref="multipleTable"
            :data="tableData"
            border
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column label="门店名称" prop="store_name">
            </el-table-column>
            <el-table-column label="门店电话" prop="store_phone">
            </el-table-column>
            <el-table-column label="邀约总数" prop="total_num">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                <el-button
                    @click="handleClick(scope.row)"
                    type="primary"
                    size="small"
                    >邀约明细</el-button
                >
                </template>
            </el-table-column>
            <!-- <el-table-column label="地址">
                <template slot-scope="scope">
                    {{scope.row.province}}-{{scope.row.city}}-{{scope.row.district}}
                </template>
            </el-table-column>
            <el-table-column label="详细地址" prop="detail">
            </el-table-column>
            <el-table-column label="备注" prop="remarks">
            </el-table-column> -->
        </el-table>
        <!-- 分页控制 -->
        <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="sizeForm.page"
        @current-change="setPage">
        </el-pagination>
        <!-- <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
            <div>
                <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
                将导出满足筛选条件的门店!
                </p>
                <el-radio v-model="exportType" :label="1">当前页</el-radio>
                <el-radio v-model="exportType" :label="2">导出所有</el-radio>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportShows">取 消</el-button>
                <el-button type="primary" @click="exportShop2">确 定</el-button>
            </span>
        </el-dialog> -->
        <el-dialog title="查看明细" :visible.sync="invitedialog" width="50%">
        <el-table
          row-key="id"
          ref="multipleTable"
          :data="inviteData"
          tooltip-effect="dark"
          style="width: 100%"
        >
        <el-table-column label="邀约人门店名称" prop="store_name">
        </el-table-column>
        <el-table-column label="邀约人门店电话" prop="store_phone">
        </el-table-column>
        <el-table-column label="邀约人名称" prop="user_name">
        </el-table-column>
        <el-table-column label="受邀约人名称" prop="u_name">
        </el-table-column>
        <el-table-column label="受邀约人门店名称" prop="s_name">
        </el-table-column>
        <el-table-column label="时间" prop="createtime">
        </el-table-column>
          <!-- <el-table-column
            prop="address"
            label="用户类型"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.price }}</template>
          </el-table-column> -->
          
        </el-table>
        <el-pagination
          background
          style="margin-top: 20px"
          @current-change="crtChg"
          :page-size="inviteForm.limit"
          :current-page="inviteForm.page"
          layout="total, prev, pager, next"
          :total="modelTotal"
        >
        </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="invitedialog = false">取 消</el-button>
        <el-button type="primary" @click="chooseProSele">确 定</el-button>
      </span>
    </el-dialog>
    </el-card>
</template>
<script>
import { postLiveInvite, postLiveInviteDetail, luckdrawsaddressOutexcel } from '@/api/api.js'
import { filenameFormat } from "@/utils/index";
export default {
    components:{},
    name: 'inviteRanking',
    data(){
        return{
            sizeForm: {
                limit: 10, //每页显示条数
                page: 1,
                live_id: 0, //直播间id
                // playback_id: 0, //直播记录id
                type: 1, //1.即时，2.结束后
            },
            total: 0, //总条数
            dateTime: '', //时间区间
            tableData:[], //列表数据
            handleSelect: [], //多选数据
            idArry: '' , //多选id组
            exportShow: false,
            exportType: '',
            inviteData: [], // 邀请明细
            invitedialog: false,
            modelTotal: 0,
            inviteForm: {
                limit: 10,
                page: 1,
                live_id: '', //直播id
                playback_id: '', //直播记录id
                type: 1, //1.即时，2.结束后
                store_id: '', //门店id
            }
        }
    },
    created(){
        if (this.$route.query.liveid) {
            console.log('this.$route.query.id====',this.$route.query.liveid)
            this.sizeForm.live_id = this.$route.query.liveid
        }
        this.getlist()
    },
    methods:{
        goBack() {
            console.log('go back');
            this.$router.back(-1)
        },
        // 邀约列表
        async getlist() {
            // this.form.live_id = this.$route.query.liveid;
            const { data } = await postLiveInvite(this.sizeForm);
            if (data.code != 200) return this.$message.error(data.data);
            this.tableData = data.data.data;
            this.total = data.data.total;
        },
        // 邀约明细
        async invitelist() {
            // this.form.live_id = this.$route.query.liveid;
            const { data } = await postLiveInviteDetail(this.sizeForm);
            if (data.code != 200) return this.$message.error(data.data);
            console.log(data)
            this.inviteData = data.data.data;
            this.modelTotal = data.data.total;
        },
        // 绑定新门店
        bindingShop(id){
            this.$refs['bindingShopModel'].modelShow(id)
        },
        // 子组件添加成功回调
        submitSuccess(e){
            this.$message({ type: 'success', message: e })
            this.getlist()
        },
        // 取消导出
        exportShows() {
            this.exportShow = false;
        },
        // 导出订单
        // exportShop() {
        //     this.exportShow = true;
        // },
        // 确认导出
        async exportShop() {
            if(!this.sizeForm.luckdraws_id){
                this.$message.error("请查询id筛选相应会议奖项进行导出!");
                return
            }
            let { data, types, request } = await luckdrawsaddressOutexcel(this.sizeForm, "blob");
            let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
            console.log('blob=======',blob);
            let objectUrl = URL.createObjectURL(blob); //生成下载链接
            let a = document.createElement("a"); //创建a标签用于文件下载
            a.href = objectUrl; //赋值下载路径
            a.download = filenameFormat(
              request.getResponseHeader("content-disposition")
            ); //下载的文件名称（非必填）
            // console.log('filename====',filename)
            document.body.appendChild(a); //插入DOM树
            a.click(); //点击a标签触发
            document.body.removeChild(a); //删除a标签
                // this.form.export = '';
        },
        // 查询
        onSearch(){
            this.sizeForm.page = 1
            this.getlist()
        },
        // 列表多选
        handleSelectionChange(e){
            this.handleSelect = e
            let id = []
            this.handleSelect.forEach(item => {
                id.push(item.id)
            })
            this.idArry = id.join(',')
            
        },
        // 列表多选绑定
        toggleSelectionHidden(){
            this.$refs['bindingShopModel'].modelShow(this.idArry)
        },
        // 分页控制
        setPage(e){
            this.sizeForm.page = e
            this.getlist()
        },
         // 打开邀约明细弹窗
    handleClick(e) {
        console.log(e)
        this.inviteForm.playback_id = e.id
        this.inviteForm.store_id = e.store_id
        this.inviteForm.live_id = this.sizeForm.live_id
        this.invitedialog = true;
        this.invitelist();
    },
      // 列表确定
    chooseProSele() {
      this.invitedialog = false;
    },
// 组件切换分页
    crtChg(e) {
      this.inviteForm.page = e;
      this.invitelist();
    },
    }
}
</script>
<style lang="less" scope>
.app{
    padding: 30px;
}
/deep/ .el-table {
    .cell {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
} 


</style>